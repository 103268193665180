import { defineRule, configure } from 'vee-validate'
// eslint-disable-next-line camelcase
import { required, email, min, alpha_dash, alpha_spaces, alpha_num, numeric, digits } from '@vee-validate/rules'
import de from '@vee-validate/i18n/dist/locale/de.json'
import { localize, setLocale } from '@vee-validate/i18n'
import { checkUsername } from '@/services/api/user/api'

defineRule('required', required)
defineRule('email', email)
defineRule('min', min)
defineRule('alpha_dash', alpha_dash)
defineRule('alpha_spaces', alpha_spaces)
defineRule('alpha_num', alpha_num)
defineRule('numeric', numeric)
defineRule('digits', digits)

defineRule('currency', (value: string) => {
  const regex = /^([0-9]+$)|(\d+(?:,\d{0,2})$)|(,\d{1,2}$)/
  if (!regex.test(value)) return 'Bitte eine Währung in EUR eingeben'

  return true
})

defineRule('userident_or_email', (value: string) => {
  if (alpha_dash(value, ['de']) || email(value)) {
    return true
  }
  return 'Benutzername oder E-Mail darf alphanumerische Zeichen, Striche, Unterstriche oder eine gültige E-Mail-Adresse enthalten.'
})

configure({
  generateMessage: localize({
    de
  })
})

setLocale('de')
